* {
  user-select: none !important;
  cursor: default !important;
}

:root {
  --theme-color: #02b3ff;
}

input[type=range] {
  width: 100%;
  height: 27px;
  margin: 10px 0;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}
input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  border: 0px;
  border-radius: 8px;
  cursor: pointer;
  background: var(--theme-color);
}

input[type=range]::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 20px;
  background: #FFFFFF;
  box-shadow: 0 0 4px black;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5.5px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--theme-color);
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 10px;
  border: 0;
  border-radius: 5px;
  background: var(--theme-color);
  cursor: pointer;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #FFFFFF;
  cursor: pointer;
}

body {
  --plyr-video-background: transparent;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: system-ui;
  overscroll-behavior: contain;
}

.hide {
  display: none !important;
}

::-webkit-scrollbar {
  display: none;
}

.input_container {
  z-index: 2;
  position: relative;
}

.dynamic_background {
  background: linear-gradient(270deg, #141e30, #243b55);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#input_container {
  width: 100vw;
  height: 100vh;
}

#input_container input {
  width: 100%;
  height: 100%;
  opacity: 0;
}

#button_container {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  pointer-events: none;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#tap_to_open {
  margin-top: -16px;
  font-size: 1.2em;
  opacity: 0.8;
}

#file_loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

#button_container img {
  width: 30vw;
  max-width: 400px;
}

video {
  visibility: hidden;
}

#canvas_container {
  background: black;
}

#stage {
  width: 100vw;
  height: 100vh;
}

#main_player {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

#canvas_container,
#video_container,
.plyr {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

#top_bar {
  top: 0;
  width: calc(100vw - 40px);
  padding: 20px;
  color: white;
  background: linear-gradient(0, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
  z-index: 1;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: transform 300ms, visibility 300ms, opacity 300ms;
}

#top_bar select,
#top_bar button {
  border: 0;
  background: transparent;
  color: white;
}

#top_bar option {
  color: black;
}

#top_bar.hidden {
  transform: translateY(-50%);
  visibility: hidden;
  opacity: 0;
}

#settings {
  transform: rotate(0);
  transition: transform 300ms;
}

.settings-on #settings {
  transform: rotate(180deg);
}

.settings-on #settings-panel {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

#settings-panel {
  transform: translateY(-20%);
  opacity: 0;
  visibility: hidden;
  transition: transform 300ms, visibility 300ms, opacity 300ms;
  display: flex;
}

#settings-panel > div {
  margin: 0 16px;
}

#scale_mode {
  margin-top: 10px;
  display: block;
}